import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react';
import { useAuth } from 'state/AuthProvider';
import { useApplication } from 'state/ApplicationProvider';
import PropTypes from 'prop-types';
import { fetchMatterMakerData } from 'actions/matterMaker';
import { errorDispatcher } from 'actions/actionUtils';
import reducer from './reducer';

const MatterMakerContext = createContext(null);

const initialState = {
  settings: {
    options: {
      enabledLenders: [],
      borrowers: [],
      howManyMatters: [],
      jurisdictions: [
        {
          value: 1,
          label: 'NSW',
        }, {
          value: 2,
          label: 'QLD',
        }, {
          value: 4,
          label: 'VIC',
        },
      ],
      securities: [
        {
          value: 1,
          label: '1',
        }, {
          value: 2,
          label: '2',
        }, {
          value: 3,
          label: '3',
        },
      ],
      tenants: [],
    },
    borrowers: [],
    matterLimit: 10,
    brokerHide: true,
    readyToSubmit: false,
    create: false,
  },
  createMatter: {
    borrowerOneId: -1,
    borrowerTwoId: -1,
    guarantorOneId: -1,
    guarantorTwoId: -1,
    brokerEmail: null,
    howManyMatters: null,
    idVerify: false,
    lenderId: null,
    setExistingMattersToDraft: false,
    originator: false,
    tenantId: null,
    numberOfSecurities: 1,
    securities: [1, 1, 1],
  },
};

function useMatterMakerHook() {
  const { authToken } = useAuth();
  const { dispatch: applicationDispatch } = useApplication();
  const getAuthTokenRef = useRef(authToken);
  const applicationDispatchRef = useRef(applicationDispatch);
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    async function fetch() {
      try {
        const response = await fetchMatterMakerData(getAuthTokenRef.current);
        dispatch({ type: 'SET_MATTER_MAKER', payload: response });
      } catch (error) {
        errorDispatcher(applicationDispatchRef.current, "We couldn't start Matter Maker, please reload the page and try again");
      }
    }
    fetch();
  }, []);

  const actions = {
    setBorrower: (payload) => {
      try {
        dispatch({ type: 'SET_BORROWER', payload });
      } catch (error) {
        errorDispatcher(applicationDispatchRef.current, "We couldn't set the borrower of the matter, please reload the page and try again");
      }
    },
    updatePayload: (payload) => {
      try {
        dispatch({ type: 'UPDATE_PAYLOAD', payload });
      } catch (error) {
        errorDispatcher(applicationDispatchRef.current, 'We couldn\'t update your matter, please reload the page and try again');
      }
    },
    updateSettings: (settings) => {
      try {
        dispatch({ type: 'UPDATE_SETTINGS', settings });
      } catch (error) {
        errorDispatcher(applicationDispatchRef.current, 'We couldn\'t update the matter settings, please reload the page and try again');
      }
    },
  };

  return {
    state,
    actions,
  };
}

export const useMatterMaker = () => {
  const contextValue = useContext(MatterMakerContext);
  if (contextValue === null) throw Error('MatterMakerContext has not been Provided!');
  return contextValue;
};

export default function MatterMakerProvider({ children }) {
  const hook = useMatterMakerHook();
  const value = useMemo(() => ({ ...hook }), [hook]);
  return (
    <MatterMakerContext.Provider value={value}>
      {children}
    </MatterMakerContext.Provider>
  );
}

MatterMakerProvider.propTypes = {
  children: PropTypes.shape().isRequired,
};
