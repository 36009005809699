/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { pdfjs, Document, Page } from 'react-pdf';
import ReactRouterPropTypes from 'react-router-prop-types';
import Helmet from 'react-helmet';
import {
  ActionBar, ConfirmAction, PageButton, Button, Panel, ResponsiveGroup, PageLoading, Modal, P, Text, Flex, H6, UL, LI, OL, Link,
  toast, H4, MessageBox, ErrorSummary, utils, Icon, Show, ZoomButton, mediaQueries, Box, Row,
} from '@galilee/lilee';
import { useAuth } from 'state/AuthProvider';
import { useMatter } from 'state/MatterProvider';
import { useDynamicForm } from 'state/DynamicFormProvider';
import { useApplication } from 'state/ApplicationProvider';
import Container from 'components/Container';
import {
  PDFNavWrapper, PDFNav, PDFNavIcon, PDFPageNumber,
} from 'components/PDFNav';
import PROGRESS_STAGES from 'enums/ProgressStages';
import {
  setDocumentSignAction, setSignDocumentReadAction, setDocumentSignatureImageUpload, unSignAction,
} from 'actions/Matter';
import {
  clearDocumentSignFields, preVerifySignDocument,
} from 'actions/Sign';
import { getPageMeta, doDownload, getSummaryStage } from 'utils';
import DynamicForm from 'components/dynamicForm';
import SignatureField from 'components/SignatureCanvas';
import WitnessModal from 'components/WitnessModal';
import PdfLoading from 'components/PdfLoading';
import Verify from 'images/Verify.svg';
import VerifySuccess from 'images/VerifySuccess.svg';
import Incomplete from 'images/Incomplete.png';
import Unsign from 'images/Unsign.png';
import PDFDocumentWrapper from 'components/PDFDocumentWrapper';
import SignFlowTypes from './SignFlowTypes';
import DisplaySignature from './components/DisplaySignature';
import PreSignatureForm from './components/PreSignatureForm';
import VerificationErrorModal from './components/VerificationErrorModal';
import PdfNotice from '../components/PdfNotice';
import BorrowerType from '../../../enums/BorrowerType';
import DownloadButton from '../components/DownloadButton';

const { useScrollToTop } = utils;
const { lessThan } = mediaQueries;

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const signatureType = {
  Yellowtail: 1,
  GreatVibes: 2,
  Custom: 3,
};

const NextActionGroup = styled(Flex)``;

NextActionGroup.defaultProps = {
  flexDirection: ['column', null, 'row'],
  alignItems: 'center',
  justifyContent: 'center',
};

const EditFormButton = ({ children, onClick, ...props }) => (
  <Button
    minWidth={['200px', null, 0, '280px']}
    fullWidth
    maxWidth="300px"
    onClick={onClick}
    color="base"
    iconColor="base60"
    iconSmall
    leftIcon={<Icon name="Edit" />}
    {...props}
  >
    Edit
  </Button>
);

const Signature = styled(Box)`
  width: 100%;
  height: auto;
  min-height: ${(p) => p.theme.space[10]};
  border: 1px solid ${(p) => p.theme.colors.base10};
  border-Radius: ${(p) => p.theme.space[6]};
  background-Color: #ffffff;
  grid-Template-Columns: auto 2fr 2fr;
  padding: ${(p) => p.theme.space[6]};
  margin-top: ${(p) => p.theme.space[6]};
`;

EditFormButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onClick: PropTypes.func.isRequired,
};

const SignPage = ({ match }) => {
  const docId = Number(match.params.id);
  const { user, authToken, support } = useAuth();
  const { isTenantSupport } = support;
  const { state, dispatch } = useMatter();
  const { state: applicationState, dispatch: applicationDispatch } = useApplication();
  const applicationDispatchRef = useRef(applicationDispatch);
  const dispatchRef = useRef(dispatch);
  const [confirmSkip, setConfirmSkip] = useState(false);
  const [signModal, setSignModal] = useState(false);
  const [pdfPath, setPDFPath] = useState(null);
  const history = useHistory();
  const [pageCount, setPageCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const {
    fields, preSignatureForm, isFormValid, fieldIds, hiddenFields, signFlowType, showAllErrors, errorSummary,
    requiresWitnessing, docId: documentId, requireAllPartiesToReSignOnEdit,
  } = useDynamicForm();
  const isWebformThenSign = signFlowType === SignFlowTypes.WebFormThenSign;
  const isModalInputThenSign = signFlowType === SignFlowTypes.ModalInputThenSign;
  const isSignOnly = signFlowType === SignFlowTypes.SignOnly;
  const [signatureUrl, setSignatureUrl] = useState(null);
  const [uploadingSignature, setUploadingSignature] = useState(false);
  const [signFlowModal, setSignFlowModal] = useState(false);
  const [witnessingRequiredModal, setWitnessingRequiredModal] = useState(false);
  const [verificationModal, setVerificationModal] = useState(false);
  const [verificationSigning, setVerificationSigning] = useState(false);
  const [verificationError, setVerificationError] = useState(null);
  const [verificationSuccess, setVerificationSuccess] = useState(false);
  const [witnessingModal, setWitnessingModal] = useState(false);
  const [confirmSignModal, setConfirmSignModal] = useState(false);
  const [doSign, setDoSign] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [confirmEdit, setConfirmEdit] = useState(false);
  const { setScrollToTop } = useScrollToTop(true);
  const [lastPageHit, setLastPageHit] = useState(false);
  const [startSignAction, setStartSignAction] = useState();
  const [confirmSignAction, setConfirmSignAction] = useState();
  const [zoomed, setZoomed] = useState(false);
  const [verificationTransactionId, setVerificationTransactionId] = useState(state?.matter?.verificationTransactionId);
  const [gettingTransactionId, setGettingTransaction] = useState(false);
  const [readPSF, setReadPSF] = useState(![1, 2].includes(preSignatureForm));
  const [signatureSelect, setSignatureSelect] = useState(null);
  const { pauseLogout } = applicationState;
  const me = (state.matter && user) ? state.matter.borrowers.find((borrower) => borrower.id === user.id) : null;
  const hasSignature = me ? me.hasSignature : false;
  const formValid = isFormValid();
  const currentDocument = state?.matterProgress?.signDocuments?.find((d) => d.id === docId) || {};
  let currentStep = { availableForDownload: !!currentDocument?.availableForDownload };
  let nextRoute = null;
  let requiresVerification = false;
  const { requireVerification, verificationCompleted } = state.verificationProgress?.find((p) => p.userId === me.id);
  const isMyVerificationCompleted = !requireVerification || verificationCompleted?.find((v) => v.id === docId)?.voiCompleted;

  useEffect(() => {
    setConfirmSkip(false);
    setSignModal(false);
    setSignatureUrl(null);
    setUploadingSignature(false);
    setSignFlowModal(false);
    setConfirmEdit(false);
    setDownloading(false);
    setConfirmSignModal(false);
    setPDFPath(null);
    setPageCount(0);
    setPageNumber(1);
    setWitnessingModal(false);
    setWitnessingRequiredModal(false);
    setVerificationModal(false);
    setVerificationSigning(false);
    setLastPageHit(false);
  }, [docId]);

  useEffect(() => {
    if (!lastPageHit && pageNumber && pageCount && pageNumber === pageCount) setLastPageHit(true);
  }, [pageNumber, pageCount, setLastPageHit, lastPageHit]);

  useEffect(() => {
    if (!authToken || !docId || isWebformThenSign || pdfPath) return;
    const fileEndpoint = currentStep.availableForDownload ? 'finalisedFile' : 'file';
    setPDFPath({ url: `${window.location.origin}/api/signDocuments/${docId}/${fileEndpoint}`, httpHeaders: { Authorization: `Bearer ${authToken}` }, withCredentials: false });
  }, [authToken, currentStep.availableForDownload, docId, isWebformThenSign, pdfPath]);

  useEffect(() => {
    if (doSign) {
      const hiddenIds = [];
      // Convert hiddenField names to Ids
      Object.keys(fieldIds).forEach((key) => {
        // Don't clear HiddenBox Fields as they should be read-only
        if (hiddenFields[key] && fields[key].componentType !== 'HiddenBox') hiddenIds.push(fieldIds[key]);
      });
      if (hiddenIds.length && !support.isTenantSupport) clearDocumentSignFields(applicationDispatch, docId, hiddenIds, authToken);
      setDocumentSignAction(applicationDispatch, dispatch, docId, authToken);
      setDoSign(false);
    }
  }, [applicationDispatch, authToken, dispatch, doSign, docId, fieldIds, fields, hiddenFields, support]);

  useEffect(() => {
    if (currentDocument.hasBeenRead || !currentDocument.isReadOnly || isTenantSupport) {
      return;
    }
    if ((currentDocument.requiresVerification && isMyVerificationCompleted) || !currentDocument.requiresVerification) {
      setSignDocumentReadAction(applicationDispatchRef.current, dispatchRef.current, docId, authToken);
    }
  }, [currentDocument.hasBeenRead, currentDocument.isReadOnly, authToken, docId, isMyVerificationCompleted, currentDocument.requiresVerification, isTenantSupport]);

  const getTransaction = async (token, verifyDocId) => {
    try {
      if (gettingTransactionId) return;
      setGettingTransaction(true);
      const result = await preVerifySignDocument(token, verifyDocId);
      if (!result.verificationTransactionId) throw new Error('null verificationTransactionId');
      setVerificationTransactionId(result.verificationTransactionId);
      setVerificationModal(true);
      setGettingTransaction(false);
    } catch {
      toast.error('Something went wrong, please reload the page and try again');
    }
  };

  useEffect(() => {
    function convertHiddenFields() {
      // Convert hiddenField names to Ids
      const hiddenIds = [];
      Object.keys(fieldIds).forEach((key) => {
        // Don't clear HiddenBox Fields as they should be read-only
        if (hiddenFields[key] && fields[key].componentType !== 'HiddenBox') hiddenIds.push(fieldIds[key]);
      });
      if (hiddenIds.length && !support.isTenantSupport) clearDocumentSignFields(applicationDispatch, docId, hiddenIds, authToken);
    }

    if (!verificationTransactionId) return;
    async function InitializeLivesignModal() {
      const { cleanNumber, countryCodeId, countryCode } = user.mobile;
      try {
        window.liveSignAsyncInit = () => {
          pauseLogout(true);
          // eslint-disable-next-line no-undef
          liveSign.init({
            transactionId: verificationTransactionId,
            initialiseMobile: cleanNumber,
            initCountryCodeId: countryCodeId,
            initPhoneCode: countryCode,
            onCancel: () => {
              pauseLogout(false);
              setVerificationModal(false);
              setVerificationError(true);
            },
            onError: () => {
              pauseLogout(false);
              setVerificationModal(false);
              setVerificationError(true);
            },
            onSuccess: () => {
              pauseLogout(false);
              setVerificationModal(false);
              setVerificationSigning(true);
              convertHiddenFields();
            },
          });
        };
      } catch (error) {
        toast.error('Something went wrong, please reload the page and try again');
      }
    }
    InitializeLivesignModal();
  }, [applicationDispatch, authToken, docId, fieldIds, fields, hiddenFields, pauseLogout, user.mobile, verificationTransactionId]);

  useEffect(() => {
    const signModalAction = async () => {
      if (!uploadingSignature || !hasSignature) return;
      setUploadingSignature(false);

      if (requiresWitnessing) {
        setSignModal(false);
        setWitnessingRequiredModal(true);
        return;
      }

      if (requiresVerification && !isMyVerificationCompleted) {
        setSignModal(false);
        await getTransaction(authToken, docId);
        return;
      }

      setDoSign(true);
      setUploadingSignature(false);
    };
    signModalAction();
  }, [hasSignature, uploadingSignature, requiresWitnessing, requiresVerification, isMyVerificationCompleted, authToken, docId]);

  useEffect(() => {
    if (currentDocument.myProgress !== 'Completed' || !verificationSigning) return;
    setVerificationSigning(false);
    setVerificationSuccess(true);
  }, [currentDocument.myProgress, verificationSigning]);

  const loadingPDF = pdfPath === null && !isWebformThenSign;

  if (!state.matter || !state.matterProgress || !authToken || !docId || loadingPDF || docId !== documentId) return <PageLoading />;

  const pageMeta = getPageMeta(state.matter, state.matterProgress, PROGRESS_STAGES.SIGN, docId);

  const { isCompletedByMe, isNotEditable, isSignedByAnyone } = pageMeta;
  currentStep = pageMeta.currentStep;
  requiresVerification = currentStep.requiresVerification;
  nextRoute = pageMeta.nextRoute;

  const signAction = async (isActive) => {
    if (!isActive) {
      setConfirmSkip(true);
      return;
    }

    if (!hasSignature) {
      setSignModal(true);
      return;
    }

    if (requiresWitnessing) {
      setWitnessingRequiredModal(true);
      return;
    }

    if (requiresVerification && !isMyVerificationCompleted) {
      setSignModal(false);
      await getTransaction(authToken, docId);
      return;
    }

    if (confirmSignModal) setDoSign(true);
    setConfirmSignModal(!confirmSignModal);
  };

  const submitSignature = async () => {
    if (!signatureSelect || uploadingSignature) return;
    if (!signatureUrl && signatureSelect === signatureType.Custom) return;
    setUploadingSignature(true);
    await setDocumentSignatureImageUpload(dispatch, applicationDispatch, state.matter.matterId, user.id, signatureUrl, signatureSelect, authToken);
    setSignModal(false);
  };

  const showValidationErrors = () => {
    setConfirmSkip(false);
    if (isWebformThenSign) {
      showAllErrors();
      setScrollToTop(true);
    }
  };

  const saveModalInputIfValid = () => {
    if (isCompletedByMe) {
      setSignFlowModal(false);
    } else if (formValid) {
      setSignFlowModal(false);
      signAction(true);
    } else {
      showAllErrors();
      setScrollToTop(true);
    }
  };

  const doConfirmEdit = () => {
    unSignAction(applicationDispatch, dispatch, docId, authToken);
    setConfirmEdit(false);
  };

  const startSignFlowModal = () => {
    if (Object.keys(hiddenFields).every((key) => hiddenFields[key])) {
      saveModalInputIfValid();
    } else {
      setSignFlowModal(true);
    }
  };

  const startVOIOnlyFlow = () => {
    const VOIOnlyFlow = async () => {
      if (requireVerification && !isMyVerificationCompleted && currentDocument.isReadOnly) {
        await getTransaction(authToken, docId);
      }
    };
    VOIOnlyFlow();
  };

  const getReviewInformationButton = () => (
    <Button
      maxWidth="300px"
      onClick={() => startSignFlowModal()}
      color="base"
      iconColor="base60"
      iconSmall
      leftIcon={(<Icon name="Edit" />)}
      lineHeight={['15px', '24px']}
    >
      Review Information
    </Button>
  );

  const getPagingButton = () => (
    <PageButton
      actionComplete={isCompletedByMe || lastPageHit}
      totalPages={pageCount}
      currentPage={pageNumber}
      nextPageClick={() => setPageNumber(pageNumber + 1)}
      previousPageClick={() => setPageNumber(pageNumber - 1)}
    />
  );

  const readOnlyMessage = 'You are not required to sign this document. Please read and click next.';

  let nextAction = null;

  if (isWebformThenSign && !isNotEditable) {
    if (currentDocument.isReadOnly) {
      if (currentDocument.requiresVerification) {
        nextAction = !isCompletedByMe ? (
          <Button fullWidth maxWidth="300px" onClick={() => startVOIOnlyFlow()} color="secondary">
            {gettingTransactionId
              ? <Icon name="loading" color="base80" spin />
              : 'Read & Verify'}
          </Button>
        ) : readOnlyMessage;
      } else {
        nextAction = readOnlyMessage;
      }
    } else if (isCompletedByMe) {
      nextAction = <EditFormButton onClick={() => setConfirmEdit(true)} />;
    } else if (!isCompletedByMe) {
      nextAction = (
        <NextActionGroup>
          {isSignedByAnyone && requireAllPartiesToReSignOnEdit && <EditFormButton mr={[0, null, 5]} mb={[5, null, 0]} onClick={() => setConfirmEdit(true)} />}
          <Button disabled={isTenantSupport} minWidth={['200px', null, 0, '280px']} fullWidth maxWidth="300px" onClick={() => signAction(formValid)} color="secondary">
            {gettingTransactionId
              ? <Icon name="loading" color="base80" spin />
              : 'Sign Now'}
          </Button>
        </NextActionGroup>
      );
    }
  }

  if (isModalInputThenSign && !isNotEditable) {
    if (currentDocument.isReadOnly) {
      if (currentDocument.requiresVerification) {
        nextAction = !isCompletedByMe ? (
          <Button fullWidth maxWidth="300px" onClick={() => startVOIOnlyFlow()} color="secondary">
            {gettingTransactionId
              ? <Icon name="loading" color="base80" spin />
              : 'Read & Verify'}
          </Button>
        ) : readOnlyMessage;
      } else {
        nextAction = readOnlyMessage;
      }
    } else if (!isCompletedByMe && !lastPageHit) {
      nextAction = getPagingButton();
    } else if (isCompletedByMe) {
      nextAction = getReviewInformationButton();
    } else if (lastPageHit && !startSignAction) {
      nextAction = (
        <ConfirmAction
          message="We just need a bit more information and then you can sign."
          dismissText="OK, GOT IT"
          onConfirm={() => setStartSignAction(true)}
          width=""
        >
          {getPagingButton()}
        </ConfirmAction>
      );
    } else if (lastPageHit && startSignAction) {
      nextAction = (
        <Button fullWidth maxWidth="300px" onClick={() => startSignFlowModal()} color="secondary">Complete &amp; Sign</Button>
      );
    }
  }

  if (isSignOnly && !isNotEditable) {
    if (currentDocument.isReadOnly) {
      if (currentDocument.requiresVerification) {
        nextAction = !isCompletedByMe ? (
          <Button fullWidth maxWidth="300px" onClick={() => startVOIOnlyFlow()} color="secondary">
            {gettingTransactionId
              ? <Icon name="loading" color="base80" spin />
              : 'Read & Verify'}
          </Button>
        ) : readOnlyMessage;
      } else {
        nextAction = readOnlyMessage;
      }
    } else if (!isCompletedByMe && !lastPageHit) {
      nextAction = getPagingButton();
    } else if (isCompletedByMe) {
      nextAction = null;
    } else if (lastPageHit && !confirmSignAction) {
      nextAction = (
        <ConfirmAction
          message={requiresVerification ? 'You need to verify your ID in order to sign this document.' : "Now you've read everything you're all ready to sign."}
          dismissText="OK, GOT IT"
          onConfirm={() => setConfirmSignAction(true)}
          width=""
        >
          {getPagingButton()}
        </ConfirmAction>
      );
    } else if (lastPageHit && confirmSignAction) {
      nextAction = (
        <Button disabled={isTenantSupport} fullWidth maxWidth="300px" onClick={() => signAction(true)} color={formValid ? 'secondary' : 'base'}>
          {gettingTransactionId
            ? <Icon name="loading" color="base80" spin />
            : 'Sign Now'}
        </Button>
      );
    }
  }

  let readBeforeSign = null;
  if (signModal || confirmSignModal) {
    readBeforeSign = (
      <OL fontSize={1} mb={7}>
        <LI>
          <Flex>
            <Text>
              have read the entire document:
            </Text>
            <Link inline="true" onClick={() => doDownload(currentStep, setDownloading, 'sign', docId, authToken)}>{downloading ? 'downloading' : 'download'}</Link>
          </Flex>
        </LI>
        <LI><Text>agree to be legally bound by the document</Text></LI>
      </OL>
    );
  }

  const pdf = isWebformThenSign ? null : (
    <Panel.Section userSelect={false} p="0">
      <PDFPageNumber pageNumber={pageNumber} pageCount={pageCount} />
      <PDFDocumentWrapper zoomed={zoomed}>
        <PDFNavWrapper show={pageNumber > 1}>
          <PDFNav leftNav onClick={() => setPageNumber((pageNumber - 1))}><PDFNavIcon name="NavLeft" /></PDFNav>
        </PDFNavWrapper>
        <Document
          loading={<PdfLoading />}
          file={pdfPath}
          onLoadSuccess={({ numPages }) => setPageCount(numPages)}
        >
          <Page
            width={1034}
            loading={<PdfLoading />}
            pageNumber={pageNumber}
          />
        </Document>
        <PDFNavWrapper show={pageCount && pageNumber !== pageCount}>
          <PDFNav pulse={pageNumber === 1} onClick={() => setPageNumber((pageNumber + 1))}><PDFNavIcon name="NavRight" /></PDFNav>
        </PDFNavWrapper>
        <Show breakpoints={[0, 1]}>
          <ZoomButton.Wrapper bottom={zoomed ? '16px' : '80px'}>
            <ZoomButton zoomed={zoomed} onClick={() => setZoomed(!zoomed)} />
          </ZoomButton.Wrapper>
        </Show>
      </PDFDocumentWrapper>
    </Panel.Section>
  );

  const errors = currentStep.errors ? <ErrorSummary errors={currentStep.errors} title="There were errors" mb={[5, 6]} /> : null;
  const shouldShowAdditionalInformation = (isCompletedByMe || (isSignedByAnyone && requireAllPartiesToReSignOnEdit)) && !isNotEditable;

  return (
    <Container>
      <PdfNotice isWebformThenSign={isWebformThenSign} status={getSummaryStage(state.matterProgress, user.id).stage} />
      {verificationModal && (
        <Helmet>
          <script src={applicationState.appSettings.liveSignWidgetUrl} async defer />
        </Helmet>
      )}
      <Container center>
        <Panel mb="0" p={[4, 6]}>
          <Panel.Subtitle>Complete &amp; Sign</Panel.Subtitle>
          <Panel.Title pb={[0]}>
            {currentStep.name}
            <br />
            <DownloadButton
              progress={state.matterProgress.signDocuments.find((doc) => doc.id === docId)}
              sectionType="sign"
              docId={docId}
              authToken={authToken}
              verificationProgress={state.verificationProgress}
            />
          </Panel.Title>
          {/* <pre>{JSON.stringify(applicationState, null, 4)}</pre> */}
          {errors}
          {isWebformThenSign && (
            <Panel.Section borderCollapse>
              <ErrorSummary errors={errorSummary} title="There were errors" mb={[5, 6, 7, 8]} />
              <DynamicForm isNotEditable={isNotEditable || isTenantSupport} isCompletedByMe={isCompletedByMe} isSignedByAnyone={isSignedByAnyone} />
            </Panel.Section>
          )}

          {isModalInputThenSign && (
            <>
              {pdf}
              {signFlowModal && (
                <Modal maxWidth="1024px">
                  <Modal.Close onClose={() => setSignFlowModal(false)} />
                  <Modal.Body p={[5, 7, 8]}>
                    <Modal.Subtitle>Complete the following</Modal.Subtitle>
                    {shouldShowAdditionalInformation && (
                      <>
                        <Modal.Title lineHeight="compact">Additional Information</Modal.Title>
                        <MessageBox
                          maxWidth="430px"
                          mb={[6, 7]}
                          label="Warning"
                        >
                          <Flex flexDirection="column" alignItems="flex-start">
                            This document has already been signed. By clicking &apos;Edit Information&apos;
                            {' '}
                            {requireAllPartiesToReSignOnEdit ? 'all borrowers' : 'you'}
                            {' '}
                            will need to re-sign.
                            <Button
                              disabled={isTenantSupport}
                              mt="5"
                              mb="3"
                              onClick={() => unSignAction(applicationDispatch, dispatch, docId, authToken)}
                              color="outline"
                              iconSmall
                              iconColor="base"
                              leftIcon={<Icon name="Edit" />}
                            >
                              Edit Information
                            </Button>
                          </Flex>
                        </MessageBox>
                      </>
                    )}
                    {errors}
                    <DynamicForm isNotEditable={isNotEditable} isCompletedByMe={isCompletedByMe} isSignedByAnyone={isSignedByAnyone} />
                    <ErrorSummary errors={errorSummary} title="There were errors" />
                    <Modal.ActionContainer width="100%" justifyContent="flex-start">
                      <ResponsiveGroup fullWidthBreakpointIndex={0}>
                        <Button color={formValid ? 'secondary' : 'base'} onClick={() => saveModalInputIfValid()}>{isCompletedByMe ? 'Done' : 'Next'}</Button>
                      </ResponsiveGroup>
                    </Modal.ActionContainer>
                  </Modal.Body>
                </Modal>
              )}
            </>
          )}

          {isSignOnly && pdf}
        </Panel>

        <ActionBar
          showNext={isCompletedByMe || isNotEditable}
          onNextClick={() => history.push(nextRoute)}
        >
          {nextAction}
        </ActionBar>

        {signModal && (
          <Modal>
            <Modal.Close onClose={() => { setSignModal(false); setReadPSF(![1, 2].includes(preSignatureForm)); }} />
            <Modal.Body>
              <Modal.Subtitle>Signing Documents</Modal.Subtitle>
              {!readPSF
                ? (
                  <>
                    <Modal.Title lineHeight="compact">IMPORTANT</Modal.Title>
                    <PreSignatureForm type={preSignatureForm} />
                    <Modal.ActionContainer width="100%" justifyContent="flex-start">
                      <ResponsiveGroup>
                        <Button color="secondary" onClick={() => setReadPSF(true)}> Next </Button>
                      </ResponsiveGroup>
                    </Modal.ActionContainer>
                  </>
                )
                : (
                  <>
                    <Modal.Title lineHeight="compact">Add your signature</Modal.Title>
                    <P>Use your mouse or finger to sign your name in the box below. We’ll use this to digitally sign each time it is required.</P>
                    <Text fontSize={1}>
                      By creating a signature and clicking on the sign button you confirm that you:
                    </Text>
                    {readBeforeSign}

                    <H4>CHOOSE A SIGNATURE STYLE</H4>
                    <Box mb="20px" width="100%">
                      <Signature onClick={() => { setSignatureSelect(signatureType.Yellowtail); setSignatureUrl(null); }}>
                        <Row alignItems="center">
                          <Icon iconSize="sm" name={signatureSelect === signatureType.Yellowtail ? 'SelectedCircle' : 'EmptyCircle'} color="base40" />
                          <Text ml={7} color="#072438" fontFamily="Yellowtail" fontSize="40px">
                            {user.firstName}
                            {' '}
                            {user.lastName}
                          </Text>
                        </Row>
                      </Signature>
                      <Signature onClick={() => { setSignatureSelect(signatureType.GreatVibes); setSignatureUrl(null); }}>
                        <Row alignItems="center">
                          <Icon iconSize="sm" name={signatureSelect === signatureType.GreatVibes ? 'SelectedCircle' : 'EmptyCircle'} color="base40" />
                          <Text ml={7} color="#072438" fontFamily="Great Vibes" fontSize="40px">
                            {user.firstName}
                            {' '}
                            {user.lastName}
                          </Text>
                        </Row>
                      </Signature>
                      <Signature onClick={() => { setSignatureSelect(signatureType.Custom); }}>
                        <Row mt="auto" alignItems="center" mb={signatureSelect === signatureType.Custom ? 6 : 0}>
                          <Icon iconSize="sm" name={signatureSelect === signatureType.Custom ? 'SelectedCircle' : 'EmptyCircle'} color="base40" />
                          <Text ml={7}>
                            Create my own
                          </Text>
                        </Row>
                        {signatureSelect === signatureType.Custom
                          && (
                            <SignatureField
                              onSignatureUpdate={(sigUrl) => setSignatureUrl(sigUrl)}
                            />
                          )}
                      </Signature>
                    </Box>
                    <Modal.ActionContainer width="100%" justifyContent="flex-start">
                      <ResponsiveGroup fullWidthBreakpointIndex={0}>
                        <Row>
                          <Button disabled={isTenantSupport} mr={6} color="secondary" onClick={() => submitSignature()}>Sign</Button>
                          <Button disabled={isTenantSupport} color="base" onClick={() => { setSignModal(false); setReadPSF(![1, 2].includes(preSignatureForm)); }}>Sign Later</Button>
                        </Row>
                      </ResponsiveGroup>
                    </Modal.ActionContainer>
                  </>
                )}
            </Modal.Body>
          </Modal>
        )}

        {(confirmSignModal) && (
          <Modal>
            <Modal.Close onClose={() => setConfirmSignModal(false)} />
            <Modal.Body>
              <Modal.Subtitle>Signing Documents</Modal.Subtitle>
              <Modal.Title lineHeight="compact">
                Sign your
                {' '}
                {currentStep.name}
              </Modal.Title>
              <P>You&apos;re ready to sign. We&apos;ve saved your signature so you don&apos;t have to do that again.</P>
              <Text fontSize={1}>
                By clicking on the sign button you confirm that you:
              </Text>
              {readBeforeSign}
              <PreSignatureForm type={preSignatureForm} />
              {!isTenantSupport
              && <DisplaySignature matterId={state.matter.matterId} />}
              <Modal.ActionContainer width="100%" justifyContent="flex-end">
                <ResponsiveGroup>
                  <Button disabled={isTenantSupport} onClick={() => signAction(true)} color="secondary">Sign</Button>
                </ResponsiveGroup>
              </Modal.ActionContainer>
            </Modal.Body>
          </Modal>
        )}

        {witnessingRequiredModal && (
          <Modal>
            <Modal.Close onClose={() => setWitnessingRequiredModal(false)} />
            <Modal.Body>
              <Modal.Subtitle>Witnessing</Modal.Subtitle>
              <Modal.Title lineHeight="compact">Signing Witness Required</Modal.Title>
              <MessageBox my={[5, 6, 7]} label="Please Note:">Before continuing, ensure that you have someone present to witness you signing the mortgage.</MessageBox>
              <H6>The witness must be aged 18 years or older and is not a party to the loan, and:</H6>
              <UL>
                <LI>have known you for more than 12 months, OR</LI>
                <LI>sighted your identifying documentation, AND</LI>
                <LI>has read and understood their obligations as a witness, as set out in the Witness Execution document attached to the mortgage.</LI>
              </UL>
              <Modal.ActionContainer width="100%" justifyContent="space-between">
                <Button disabled={isTenantSupport} color="base" onClick={() => setWitnessingRequiredModal(false)}>Cancel</Button>
                <Button disabled={isTenantSupport} color="secondary" onClick={() => { setWitnessingRequiredModal(false); setWitnessingModal(true); }}>Sign</Button>
              </Modal.ActionContainer>
            </Modal.Body>
          </Modal>
        )}

        {verificationModal && (
          <Modal>
            <Modal.IllustrationBody image={Verify}>
              <Modal.Close onClose={() => { setVerificationModal(false); }} />
              <Modal.Body>
                <Modal.Subtitle>VOI</Modal.Subtitle>
                <Modal.Title lineHeight="compact">Verify your identity</Modal.Title>
                <Text mb={[7, null, 8]}>We are ready to verify your identity with livesign. Click the button to get started.</Text>
                <Button disabled={isTenantSupport} id="livesign-verify" color="secondary" data-style="override">Start verification</Button>
                <Flex flexDirection="column">
                  <H4 mt={[7, null, 8]} mb={4}>What is livesign?</H4>
                  <Text>livesign is our partner app that we use for verifying your identity. Once you have completed the process you will be directed back to KWIL.</Text>
                </Flex>
              </Modal.Body>
            </Modal.IllustrationBody>
          </Modal>
        )}

        {verificationSigning && (
          <Modal>
            <Modal.IllustrationBody image={Verify}>
              <Modal.Body>
                <Modal.Subtitle>Signing your documents</Modal.Subtitle>
                <Modal.Title lineHeight="compact">Do not refresh or leave this page</Modal.Title>
                <Flex flexDirection="column">
                  <Text>Please wait while we finish up with your documents.</Text>
                </Flex>
              </Modal.Body>
              <Flex alignItems="center" justifyContent="center" width="100%" height="100px">
                <Icon name="Loading" color="base80" size="80px" mb="4" spin />
              </Flex>
            </Modal.IllustrationBody>
          </Modal>
        )}

        {verificationError && (
          <VerificationErrorModal invalidUserName={null} errorType={verificationError} close={() => setVerificationError(null)} />
        )}

        {verificationSuccess && (
          <Modal>
            <Modal.IllustrationBody image={VerifySuccess}>
              <Modal.Close onClose={() => { setVerificationSuccess(false); }} />
              <Modal.Body height="100%">
                <Modal.Subtitle mb="5">Identity verified</Modal.Subtitle>
                <Modal.Title lineHeight="compact">Thank you, your identity has been verified.</Modal.Title>
                <Button disabled={isTenantSupport} color="secondary" onClick={() => setVerificationSuccess(false)}>Continue</Button>
              </Modal.Body>
            </Modal.IllustrationBody>
          </Modal>
        )}

        {witnessingModal && <WitnessModal docId={docId} closeAction={() => setWitnessingModal(false)} />}

        {confirmSkip && (
          <Modal>
            <Modal.Close onClose={() => setConfirmSkip(false)} />
            <Modal.IllustrationBody image={Incomplete}>
              <Modal.Subtitle>Incomplete Document</Modal.Subtitle>
              <Modal.Title lineHeight="compact">Sorry, you can&apos;t sign just yet.</Modal.Title>
              <P>Please make sure you have completed all fields. Don&apos;t worry, we&apos;ll show you the ones you&apos;ve missed.</P>
              <Modal.ActionContainer>
                <ResponsiveGroup>
                  <Button color="secondary" onClick={showValidationErrors}>Ok</Button>
                </ResponsiveGroup>
              </Modal.ActionContainer>
            </Modal.IllustrationBody>
          </Modal>
        )}

        {confirmEdit && (
          <Modal>
            <Modal.Close onClose={() => setConfirmEdit(false)} />
            <Modal.IllustrationBody image={Unsign}>
              <Modal.Subtitle>Signing Documents</Modal.Subtitle>
              <Modal.Title>Making changes will unsign this document</Modal.Title>
              <P>
                {`This document has already been signed. By clicking 'Confirm Edit' ${requireAllPartiesToReSignOnEdit ? 'all Borrowers' : 'you'} will need to re-sign.`}
              </P>
              <Modal.ActionContainer>
                <ResponsiveGroup>
                  <Button disabled={isTenantSupport} color="secondary" onClick={() => doConfirmEdit()}>Confirm Edit</Button>
                </ResponsiveGroup>
              </Modal.ActionContainer>
            </Modal.IllustrationBody>
          </Modal>
        )}
      </Container>
    </Container>
  );
};

SignPage.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
};

export default SignPage;
