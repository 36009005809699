import React, { useState } from 'react';
import styled from 'styled-components/macro';
import {
  Checkbox, Flex,
} from '@galilee/lilee';
import { useMatterMaker } from 'state/matterMaker/MatterMakerProvider';

import Broker from './Broker';

const ColumnContainer = styled(Flex)`
  flex-direction: column;
`;

const OptionalSettings = () => {
  const { actions } = useMatterMaker();
  const [checked, setChecked] = useState(false);
  const [IdVerify, setIdVerify] = useState(false);
  const [hideMatter, setHideMatter] = useState(false);
  const [originator, setOriginator] = useState(false);

  const onChangeIdVerify = () => {
    setIdVerify(!IdVerify);
    actions.updatePayload({ idVerify: !IdVerify });
  };
  const onChangeHideMatters = () => {
    setHideMatter(!hideMatter);
    actions.updatePayload({ setExistingMattersToDraft: !hideMatter });
  };
  const onChangeOriginator = () => {
    setOriginator(!originator);
    actions.updatePayload({ originator: !originator });
  };

  return (
    <>
      <Checkbox
        mt="5"
        mb="5"
        label={checked ? 'Hide advanced settings' : 'Show advanced settings'}
        checked={checked}
        onChange={() => setChecked(!checked)}
      />
      {!!checked && (
      <ColumnContainer>
        <Checkbox
          mb="5"
          checked={IdVerify}
          onChange={onChangeIdVerify}
          label="(Optional) Would you like the sign document to utilise ID verification?"
        />
        <Checkbox
          mb="5"
          checked={hideMatter}
          onChange={onChangeHideMatters}
          label="(Optional) Hide matters for the selected borrower(s)"
        />
        <Checkbox
          mb="5"
          checked={originator}
          onChange={onChangeOriginator}
          label="(Optional) Set the originator to AFG?"
        />
        <Broker />
      </ColumnContainer>
      )}
    </>
  );
};

export default OptionalSettings;
